import React, { Suspense, lazy } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
// import About from "./components/About";
// import Registration from "./components/Registration";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const RegistrationPage = lazy(() => import("./components/Registration"));
const AboutPage = lazy(() => import("./components/About"));

function App() {
  AOS.init();
  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h1 className="heading-secondary">Loading...</h1>
        </div>
      }
    >
      <Router>
        <Navigation />
        <Switch>
          <Route exact path="/" component={Home} />
          <AboutPage exact path="/about" />
          <RegistrationPage exact path="/registration" />
        </Switch>
        <Footer />
      </Router>
    </Suspense>
  );
}

export default App;
