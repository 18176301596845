import { Card, CardContent } from "@material-ui/core";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import pawn from "../imgs/pawn.png";
import knight from "../imgs/horse.png";
import king from "../imgs/king.png";

function CoursePlans() {
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);

  function handleFlip1(event) {
    event.preventDefault();
    setIsFlipped1((prev) => !prev);
    setIsFlipped2(() => false);
    setIsFlipped3(() => false);
  }
  function handleFlip2(event) {
    event.preventDefault();
    setIsFlipped1(() => false);
    setIsFlipped2((prev) => !prev);
    setIsFlipped3(() => false);
  }
  function handleFlip3(event) {
    event.preventDefault();
    setIsFlipped1(() => false);
    setIsFlipped2(() => false);
    setIsFlipped3((prev) => !prev);
  }
  return (
    <div className="course-plans u-margin-bottom-small">
      <h1
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-once="true"
        id="CoursePlans"
        className="heading-secondary u-margin-bottom-large"
      >
        Course Plans
      </h1>
      <div className="rows course-plans--row">
        <div
          className="cols-1-of-3"
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
        >
          <ReactCardFlip isFlipped={isFlipped1} flipDirection="horizontal">
            <Card className="course-plans--card">
              <CardContent>
                <div className="course-plans--card__image">
                  <img src={pawn} alt="Pawn" />
                </div>
                <div className="course-plans--card__heading">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Beginner
                  </h3>
                </div>
                <div className="course-plans--card__content">
                  <p className="paragraph">
                    A course to learn & master chess fundamentals with a
                    structured curriculum. We aim to build a{" "}
                    <span className="u-text-color">CONFIDENT CHESS PLAYER</span>{" "}
                    in you at the end of the course.
                  </p>
                </div>
                <div className="course-plans--card__button">
                  <a
                    onClick={handleFlip1}
                    href="/"
                    className="btn-cards btn-cards--beginner"
                  >
                    learn more &rarr;
                  </a>
                </div>
              </CardContent>
            </Card>
            <Card className="course-plans--card">
              <CardContent>
                <div className="course-plans--card__heading back">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Beginner
                  </h3>
                </div>
                <div className="course-plans--card__content back">
                  <ul>
                    <li>Level-1: Basic concepts</li>
                    <li>Level-2: Foundational Chess</li>
                    <li>Level-3: Basic Chess tactics</li>
                    <li>Weekly assignments</li>
                    <li>Weekly tournament</li>
                    <li>E-material and worksheets</li>
                    <li>Certification assessment</li>
                  </ul>
                </div>
                <div className="course-plans--card__button">
                  <a
                    onClick={handleFlip1}
                    href="/"
                    className="btn-cards btn-cards--advanced"
                  >
                    &larr; go back
                  </a>
                </div>
              </CardContent>
            </Card>
          </ReactCardFlip>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
          className="cols-1-of-3"
        >
          <ReactCardFlip isFlipped={isFlipped2} flipDirection="horizontal">
            <Card className="course-plans--card">
              <CardContent>
                <div className="course-plans--card__image">
                  <img src={knight} alt="Knight" />
                </div>

                <div className="course-plans--card__heading">
                  <h3 className="heading-tertiary">Intermediate</h3>
                </div>

                <div className="course-plans--card__content">
                  <p className="paragraph">
                    This course will help you to understand your game more
                    deeply. It aims to make you{" "}
                    <span className="u-text-color">TOURNAMENT READY</span> and
                    to take the game one step higher than just a hobby
                  </p>
                </div>

                <div className="course-plans--card__button">
                  <a
                    onClick={handleFlip2}
                    href="/"
                    className="btn-cards btn-cards--intermediate"
                  >
                    learn more &rarr;
                  </a>
                </div>
              </CardContent>
            </Card>
            <Card className="course-plans--card">
              <CardContent>
                <div className="course-plans--card__heading back">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Intermediate
                  </h3>
                </div>
                <div className="course-plans--card__content back">
                  <ul>
                    <li>Higher end chess tactics</li>
                    <li>Opening analysis</li>
                    <li>Endgame strategies</li>
                    <li>Weekly assignments</li>
                    <li>Weekly tournament</li>
                    <li>E-material and worksheets</li>
                    <li>Certification assessment</li>
                  </ul>
                </div>
                <div className="course-plans--card__button">
                  <a
                    onClick={handleFlip2}
                    href="/"
                    className="btn-cards btn-cards--intermediate"
                  >
                    &larr; go back
                  </a>
                </div>
              </CardContent>
            </Card>
          </ReactCardFlip>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
          className="cols-1-of-3"
        >
          <ReactCardFlip isFlipped={isFlipped3} flipDirection="horizontal">
            <Card className="course-plans--card">
              <CardContent>
                <div className="course-plans--card__image">
                  <img src={king} alt="King"></img>
                </div>

                <div className="course-plans--card__heading">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Advanced
                  </h3>
                </div>

                <div className="course-plans--card__content">
                  <p className="paragraph">
                    A course to transform your passion for chess into an
                    achievement. We aim to bridge the gaps in your chess
                    knowledge and help you move ahead with every competition to
                    become a <span className="u-text-color">GRANDMASTER</span>.
                  </p>
                </div>

                <div className="course-plans--card__button">
                  <a
                    onClick={handleFlip3}
                    href="/"
                    className="btn-cards btn-cards--advanced"
                  >
                    learn more &rarr;
                  </a>
                </div>
              </CardContent>
            </Card>
            <Card className="course-plans--card">
              <CardContent>
                <div className="course-plans--card__heading back">
                  <h3 className="heading-tertiary u-margin-bottom-small">
                    Advanced
                  </h3>
                </div>
                <div className="course-plans--card__content back">
                  <ul>
                    <li>Enhance FIDE rating</li>
                    <li>Advanced chess strategies</li>
                    <li>E-book access</li>
                    <li>Weekly assignments</li>
                    <li>Weekly tournament</li>
                    <li>Personalized training only</li>
                    <li>Certification assessment</li>
                  </ul>
                </div>
                <div className="course-plans--card__button">
                  <a
                    onClick={handleFlip3}
                    href="/"
                    className="btn-cards btn-cards--advanced"
                  >
                    &larr; go back
                  </a>
                </div>
              </CardContent>
            </Card>
          </ReactCardFlip>
        </div>
      </div>
    </div>
  );
}

export default CoursePlans;
