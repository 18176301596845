import React, { useState } from "react";
import review from "../imgs/reviews.png";
import { Container, Row, Col, ProgressBar } from "react-bootstrap";
import { Card, Avatar } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import { Carousel } from "react-bootstrap";
import Ayaan from "../imgs/Ayaan.jpeg";
import Nikit from "../imgs/Nikit.jpeg";
import Udit from "../imgs/Udit.jpeg";
import Tanmay from "../imgs/Tanmay.jpeg";
import Stuti from "../imgs/Stuti.jpeg";
import Varsha from "../imgs/Varsha.jpeg";
// import thumbsUp from "../imgs/thumbs-up.svg";
// import fist from "../imgs/fist.svg";
// import wave from "../imgs/wave.svg";
// import rock from "../imgs/rock.svg";
// import ok from "../imgs/OK.svg";

function Reviews() {
  const [reviewsArray, setReviewsArray] = useState([
    {
      image: Ayaan,
      clicked: false,
      value: 5,
      name: "Ayaan",
      parent: "Mehul Mathrani",
      content:
        "This is one of the best classes my son ever have had . He is always so excited for chess classes. Ritu (Tutor) and her team has magic to keep kids engaged , interested and motivated throughout the tenure of classes. Hats off to her . Can’t wait to refer all my colleague’s kids to take benighted of this opportunity.",
    },
    {
      image: Nikit,
      clicked: false,
      value: 4.5,
      name: "Nikit",
      parent: "Naresh Bajaj",
      content:
        "Ritu and her team is extremely talented and friendly to kids. Chessify team uses latest teaching methods and make it fun for kids to learn chess. My kids look forward to her classes every week that shows the interest generates among kids. She is a natural leader and always forefront in participating community related and charity works. Real entrepreneur with world class teacher.",
    },
    {
      image: Stuti,
      clicked: false,
      value: 5,
      name: "Stuti",
      parent: "Sandhya Agnihotri",
      content:
        "Complex game of chess presented to kids in an easily understandable form with interactive examples and puzzles. My kids thoroughly enjoyed the training sessions and they are eagerly looking forward to the next level. Kudos to you and God bless you in your future endeavours.",
    },
    {
      image: Udit,
      clicked: false,
      value: 4.5,
      name: "Udit",
      parent: "Saritha Kamath Vora",
      content:
        "Absolutely in love with Chessify club. I love how their content and teaching methodologies is age appropriate and gradual. When I tried teaching Chess to my son, he wasn't very interested. Ruchi managed to get him hooked in with her patience. Would highly recommend Chessify Club. A special mention for the weekly tournaments they organise. It really helps the kids test the waters..",
    },
    {
      image: Tanmay,
      clicked: false,
      value: 5,
      name: "Tanmay",
      parent: "Purvang Vora",
      content:
        "My son was learning chess at a young age and to help him get formal training with right tactics/approach, I enrolled him with Chessify Club. Ms. Ritu was kind enough to do an evaluation of his understanding of the game before we signed him up. Since then it's been fun and a great learning experience for my son with Ms. Ritu as a tutor and he looks forward to the training sessions and tournaments. I've recommended this to my friends and highly encourage others to join the club to help learn, develop interest and spread the love for chess!",
    },
    {
      image: Varsha,
      clicked: false,
      value: 4.5,
      name: "Varsha",
      parent: "Karthik Raju",
      content:
        "Ritu is a great teacher and keeps the kids engaged and interested. Basics are reinforced with assignments and tests and competition with tournaments every week. Highly recommend.",
    },
  ]);

  const useStyles = makeStyles((theme) => ({
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }));

  const classes = useStyles();

  const handleReadMore = (event) => {
    event.preventDefault();
    let index = event.target.id;
    // let change = reviewsArray[index];
    // change.clicked = !change.clicked;
    let newArr = [...reviewsArray];
    newArr[index].clicked = !newArr[index].clicked;
    setReviewsArray(newArr);
  };

  return (
    <section
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-once="true"
      className="club-reviews"
    >
      <div className="club-reviews__heading u-center-text">
        <h1 id="Reviews" className="heading-secondary u-margin-bottom-small">
          Know what they say about us
        </h1>
      </div>
      <div className="club-reviews__first u-margin-bottom-small">
        <Container>
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="true"
              className="review-illustration__col"
            >
              <img
                className="review-illustration__img"
                src={review}
                alt="reviews illustration"
              ></img>
            </Col>
            <Col
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="800"
              data-aos-once="true"
              className="progress-bars"
            >
              <Card className="progress-card">
                <h1 className="heading-tertiary heading-tertiary--color">
                  Based on ratings
                </h1>
                <p className="label">Course Content</p>
                <ProgressBar variant="success" now={80.5} />

                <p className="label">Assignments</p>
                <ProgressBar now={82.5} />

                <p className="label">Trainer Interaction</p>
                <ProgressBar variant="info" now={86} />

                <p className="label">Fees Structure</p>
                <ProgressBar variant="warning" now={80.5} />

                <p className="label">Overall Course</p>
                <ProgressBar variant="danger" now={83.75} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Card
          data-aos="zoom-in"
          data-aos-delay="100"
          data-aos-duration="800"
          data-aos-once="true"
          className="reviews-card"
        >
          <div className="u-center-text">
            <h1 className="heading-tertiary heading-tertiary--color u-margin-bottom-small">
              Reviews
            </h1>
          </div>
          <div className="reviews-carousel">
            <Carousel
              as="div"
              indicators={false}
              className="reviews-card__carousel"
            >
              {reviewsArray.map((student, index) => {
                return (
                  <Carousel.Item key={student.name}>
                    <div className="reviews-card__student">
                      <Avatar
                        className={`reviews-card__student--avatar ${classes.large}`}
                        alt={student.name}
                        src={student.image}
                      />
                      <div className="reviews-card__student--details">
                        <p className="reviews-card__student--parent">
                          {student.parent}
                        </p>
                        <p className="reviews-card__student--name">
                          <b>{student.name}</b>'s parent
                        </p>
                      </div>
                    </div>
                    <div className="reviews-card__content">
                      <p className="reviews-card__content--rating">
                        <Rating
                          precision={0.5}
                          name="read-only"
                          value={student.value}
                          readOnly
                        />
                      </p>
                      {student.content.length > 180 ? (
                        student.clicked === false ? (
                          <p className="paragraph u-margin-bottom-zero">
                            {student.content.substring(0, 180)}
                            ...
                            <a
                              className="u-margin-bottom-zero"
                              id={index}
                              onClick={handleReadMore}
                              href="/"
                            >
                              Read more
                            </a>
                          </p>
                        ) : (
                          <p className="paragraph u-margin-bottom-zero">
                            {student.content}..
                            <a
                              className="u-margin-bottom-zero"
                              id={index}
                              onClick={handleReadMore}
                              href="/"
                            >
                              Read less
                            </a>
                          </p>
                        )
                      ) : (
                        <p className="paragraph u-margin-bottom-zero">
                          {student.content}
                        </p>
                      )}
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </Card>
      </Container>
      <div className="last-heading u-center-text">
        <h1
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
          className="heading-secondary u-margin-bottom-small"
        >
          Book a trial now!
        </h1>
        <br />
        <a
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
          className="button button-last button-white u-margin-bottom-medium"
          href="/registration"
        >
          Register now
        </a>
      </div>
    </section>
  );
}

export default Reviews;
