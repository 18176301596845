import React from "react";
import facebook from "../imgs/icons/facebook.svg";
import linkedin from "../imgs/icons/linkedin.svg";
import instagram from "../imgs/icons/instagram.svg";
import whatsapp from "../imgs/icons/whatsapp.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content--icon-box">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/chessifyclub"
          >
            <img
              className="footer__content--icon"
              src={facebook}
              alt="Facebook"
            ></img>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/groups/13986431/"
          >
            <img
              className="footer__content--icon"
              src={linkedin}
              alt="LinkedIn"
            ></img>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/chessify_club/"
          >
            <img
              className="footer__content--icon"
              src={instagram}
              alt="Instagram"
            ></img>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://wa.me/919741466600/"
          >
            <img
              className="footer__content--icon"
              src={whatsapp}
              alt="Whatsapp"
            ></img>
          </a>
        </div>

        <p className="paragraph paragraph--footer">
          Contact us |{" "}
          <a href="mailto:chessify.club@gmail.com">
            <span className="footer__content--contact">Email</span>
          </a>{" "}
          |{" "}
          <a href="tel:+919741466600">
            <span className="footer__content--contact">+91-9741466600</span>
          </a>
        </p>
        <p className="paragraph">Copyright &copy;2021 Chessify Club </p>
      </div>
    </div>
  );
};

export default Footer;
