import React from "react";
import img1 from "../imgs/photo-1.jpeg";
import img2 from "../imgs/photo-2.jpeg";
import img3 from "../imgs/photo-3.jpeg";

function MiniAbout() {
  return (
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-once="true"
      className="about"
    >
      <div className="u-center-text u-margin-bottom-big">
        <h1 className="about__heading heading-secondary">
          Making kids fall in love with chess <span>since 2019</span>
        </h1>
      </div>
      <div className="rows">
        <div
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
          className="cols-1-of-2"
        >
          <h3 className="heading-tertiary heading-tertiary--color">
            What we do
          </h3>
          <p className="paragraph u-margin-bottom-small">
            Chessify club is an ambitious attempt to spread knowledge about
            chess and inculcate strategic thinking in kids from a younger age.
            We started in 2019 but the pandemic period gave us a boost and we
            have been growing fast ever since. We now have 7+ trainers training
            students all around the globe.
          </p>
          <h3 className="heading-tertiary heading-tertiary--color">
            Become a chess master
          </h3>
          <p className="paragraph u-margin-bottom-small">
            For us, Chess is much more than strategies and tactics. It's a way
            of life that helps one win and succeed in all different fields. Come
            join us, let's make your kids masters not just in Chess, but in
            Life!
          </p>
          <div className="u-margin-bottom-medium">
            <a
              href="/about"
              className="btn-cards btn-cards--beginner btn-cards--about"
            >
              Learn more
            </a>
          </div>
        </div>
        <div
          data-aos="zoom-in"
          data-aos-duration="800"
          data-aos-once="true"
          className="cols-1-of-2"
        >
          <div className="composition">
            <img
              src={img1}
              alt="Pic 1"
              className="composition__photo composition__photo--p1"
            />
            <img
              src={img2}
              alt="Pic 2"
              className="composition__photo composition__photo--p2"
            />
            <img
              src={img3}
              alt="Pic 3"
              className="composition__photo composition__photo--p3"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MiniAbout;
