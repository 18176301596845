import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import logo from "../imgs/logo.png";

function Navigation() {
  return (
    <div className="navigation">
      <Navbar collapseOnSelect fixed="top" bg="light" expand="lg">
        <Navbar.Brand href="/">
          <img
            style={{ marginRight: "0.5rem" }}
            alt=""
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Chessify Club{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link className="navigation__link" href="/">
              <span className="footer__content--contact footer__content--contact--nav">
                Home
              </span>
            </Nav.Link>
            <Nav.Link className="navigation__link" href="/about">
              <span className="footer__content--contact footer__content--contact--nav">
                About us
              </span>
            </Nav.Link>
            <Nav.Link
              data-toggle="collapse"
              data-target=".navbar-collapse"
              className="navigation__link"
              href="/#hoax-course-plans"
            >
              <span className="footer__content--contact footer__content--contact--nav">
                Course Plans
              </span>
            </Nav.Link>
            <Nav.Link
              data-toggle="collapse"
              data-target=".navbar-collapse"
              className="navigation__link"
              href="/#hoax-reviews"
            >
              <span className="footer__content--contact footer__content--contact--nav">
                Reviews
              </span>
            </Nav.Link>
            <NavDropdown
              title={
                <span className="footer__content--contact footer__content--contact--nav">
                  Registration
                </span>
              }
              className="navigation__link"
            >
              <NavDropdown.Item href="/registration">Form</NavDropdown.Item>
              <NavDropdown.Item href="/registration#faq">FAQs</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Navigation;
