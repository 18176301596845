/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

function Header() {
  return (
    <div className="section-header">
      <div className="section-header__hero">
        <div className="section-header__hero__text">
          <h1 className="heading-primary u-margin-bottom-medium">
            <span className="heading-primary--main">Chessify club</span>
            <span className="heading-primary--sub">
              Amplify your tactical skills
            </span>
          </h1>
          <a
            className="button button-white button-animated u-margin-bottom-medium"
            href="/registration"
          >
            Register now
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
