import React from "react";
import Header from "./Header";
import MiniAbout from "./MiniAbout";
import CoursePlans from "./CoursePlans";
import Reviews from "./Reviews";
import { withRouter } from "react-router-dom";

function Home() {
  return (
    <div className="home">
      <Header />
      <MiniAbout />
      <div id="hoax-course-plans"></div>
      <CoursePlans />
      <div id="hoax-reviews"></div>
      <Reviews />
    </div>
  );
}

export default withRouter(Home);
